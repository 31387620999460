import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import Section from '@latitude/section';
import { List, ListItem } from '@latitude/list';
import { Strong, Text } from '@latitude/text';
import {
  ALIGN,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { FeaturesSlider } from '@latitude/features-slider';

import { PageContext } from '@/context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import { ImportantInformation } from '@latitude/important-information';
import Layout from '@/components/layout';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/calculators-and-tools/personal-loan-repayment-calculator.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import PersonalLoanCalculator from '@/components/PersonalLoanCalculator2/PersonalLoanCalculator';
import {
  PL_APPLY_CTA_TRACK_ID,
  PL_SYMPLE_MINIMUM_AMOUNT,
  PL_SYMPLE_DEFAULT_PERIOD
} from '@/utils/constants';
import {
  redirectUrl,
  softQuoteHrefFunc
} from '@/components/EstimateRateWidget/utils';

import heroImage from '../../images/hero/pl-loan-repayment.webp';

import LifeCantWaitSection from '@/components/PersonalLoanPageContent/LifeCantWaitSection';
import ApplyingIsSimpleSection from '@/components/PersonalLoanPageContent/ApplyingIsSimpleSection';
import NextStepsSection from '@/components/PersonalLoanPageContent/NextStepsSection';

const COLOUR_PALE_BLUE = '#E5F6FF';
const CTA_LABEL = 'Get your personalised rate';

const WHY_CHOOSE_ITEMS = [
  {
    icon: 'icon2-snap-fingers-blue',
    title: 'Simple application',
    jsx: (
      <List>
        <ListItem>Apply online in under 7 minutes</ListItem>
        <ListItem>
          Only a few documents needed for ID and income verification
        </ListItem>
      </List>
    )
  },
  {
    icon: 'icon2-flexible-blue',
    title: 'Flexible financing',
    jsx: (
      <List>
        <ListItem>No early repayment fee on variable loans</ListItem>
        <ListItem>
          Access additional repayments you've made when you need them with
          Redraw on variable loans
        </ListItem>
      </List>
    )
  },
  {
    icon: 'icon2-handshake-blue',
    title: 'Solutions to suit you',
    jsx: (
      <List>
        <ListItem>Our team will help you through the process</ListItem>
        <ListItem>Loan options tailored to your specific needs</ListItem>
      </List>
    )
  }
];

const WHY_LATITUDE_ITEMS = [
  {
    icon: './images/why-latitude1.webp',
    title: 'Why Latitude?',
    jsx: (
      <Text color={COLOR.WHITE}>
        We've been helping Aussies manage their finances for close to a century.
        Trusted by 2.1 millions customers today, we're ready to back your
        journey, wherever it leads.
      </Text>
    )
  },
  {
    icon: './images/why-latitude2.webp',
    title: 'Award-winning Personal Loans',
    jsx: (
      <Text color={COLOR.WHITE}>
        WeMoney Best in Flexibility 2 years running.
      </Text>
    )
  }
];

// eslint-disable-next-line arrow-body-style
const PLCalculatorPage = ({ location }) => {
  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();
  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    sessionStorage.setItem('loanAmount', PL_SYMPLE_MINIMUM_AMOUNT.toString());
    sessionStorage.setItem('repaymentPeriod', PL_SYMPLE_DEFAULT_PERIOD);
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout
      location={location}
      title={PageData.title}
      metaDesc={PageData.metaDesc}
      canonical="/personal-loan-repayment-calculator/"
    >
      <main className="navigation-spacer">
        <MobileAppInstallPrompts />
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={heroImage}
            title={
              <>
                Personal Loan
                <br />
                Repayment Calculator
              </>
            }
            subTitle="How much will a Latitude Personal Loan actually cost you? Use our calculator now to explore potential repayments."
          />
        )}
        <div className="d-none d-lg-block" css="position:relative; z-index:2;">
          <StickyNavigationBranded
            items={PageData.nav}
            phoneNumber={PageData.content.phoneNumber}
            ctaHref={softQuoteHrefFunc(softQuoteHref)}
            ctaText={CTA_LABEL}
            offsetElem="[data-sticky-navigation-offset]"
            target="_self"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              label: CTA_LABEL,
              location: 'personal-loan-calculator-nav'
            }}
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <Section id="calculator" css={{ backgroundColor: COLOUR_PALE_BLUE }}>
          <PersonalLoanCalculator
            loans1
            applyCTATrackId={PL_APPLY_CTA_TRACK_ID}
          />
        </Section>
        <Section id="why-latitude" heading="Why choose a Latitude loan?">
          <FeaturesSlider
            css={`
              && {
                padding-top: 0;
              }
              && div.lfs-card {
                border-radius: 14px;
              }
            `}
            whiteTiles
            data={WHY_CHOOSE_ITEMS}
          />
        </Section>
        <Section css={{ backgroundColor: '#5E47B9' }}>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '20px'
            }}
          >
            {WHY_LATITUDE_ITEMS.map((item, index) => {
              return (
                <div
                  key={index}
                  css={{
                    flexBasis: '350px',
                    padding: '20pt 10pt'
                  }}
                >
                  <div css={{ width: '100%', height: 'auto' }}>
                    <img
                      css={{ width: '100%', objectFit: 'cover' }}
                      src={require(`${item.icon}`).default}
                      alt={item.title}
                    />
                  </div>
                  <Text
                    align={ALIGN.CENTER}
                    fontSize={FONT_SIZE.LARGE}
                    fontWeight={FONT_WEIGHT.BOLD}
                    color={COLOR.WHITE}
                  >
                    {item.title}
                  </Text>
                  <div
                    css={{
                      marginTop: '15px'
                    }}
                  >
                    {item.jsx}
                  </div>
                </div>
              );
            })}
          </div>
        </Section>
        <ApplyingIsSimpleSection
          id="how-to-apply"
          heading="Applying for a Latitude Personal Loan is simple"
        />
        <Section
          id="eligibility-criteria"
          heading="Are you eligible for a Personal Loan?"
        >
          <Text align={ALIGN.CENTER}>
            <Strong>To be eligible for a Personal Loan you must:</Strong>
          </Text>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
          >
            <TickedList>
              <ListItem>Be 18 years or over</ListItem>
              <ListItem>
                Be able to demonstrate a good credit history for the last 5
                years
              </ListItem>
              <ListItem>Be a permanent Australian resident</ListItem>
            </TickedList>
            <TickedList>
              <ListItem>Be free from bankruptcy for the last 7 years</ListItem>
              <ListItem>
                Be currently employed and earning at least $25,000 gross per
                annum
              </ListItem>
            </TickedList>
          </div>
        </Section>
        <NextStepsSection
          ctaLinkHref={softQuoteHref}
          ctaTrackingLocation="personal-loan-calculator-next-step"
        />

        <LifeCantWaitSection
          id="explore-loans"
          heading="When life can't wait Latitude is here to help"
        />
        <ImportantInformation
          data={require('@/data/json/disclaimer/personal-loan1.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
        />
      </main>
    </Layout>
  );
};

const TickedList = styled.ul`
  && {
    flex: 0 0 45%;
    min-width: 300px;
    list-style-image: url(${require('./images/tick-simple.svg').default});
  }
  && li {
    padding-bottom: 10px;
  }
`;

export default PLCalculatorPage;
